// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-dev-notes-js": () => import("./../../../src/pages/dev-notes.js" /* webpackChunkName: "component---src-pages-dev-notes-js" */),
  "component---src-pages-how-to-js": () => import("./../../../src/pages/how-to.js" /* webpackChunkName: "component---src-pages-how-to-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-dev-notes-page-js": () => import("./../../../src/templates/dev-notes-page.js" /* webpackChunkName: "component---src-templates-dev-notes-page-js" */),
  "component---src-templates-how-to-page-js": () => import("./../../../src/templates/how-to-page.js" /* webpackChunkName: "component---src-templates-how-to-page-js" */),
  "component---src-templates-markdown-page-js": () => import("./../../../src/templates/markdown-page.js" /* webpackChunkName: "component---src-templates-markdown-page-js" */)
}

